import { useEffect, useLayoutEffect, useState } from 'react';
import { Store } from 'redux';
import { initDependencies } from 'core/common/contexts';
import { useUrlLocation } from 'core/common/hooks';
import { RootInitialState } from 'core/common/store';
import createStore from 'core/common/store/store';
import { getThemeType } from 'core/theme/utils/getFlowThemeType';
import { appnebulaConfig } from './appnebulaConfig';

export const useApp = () => {
  const { location: urlLocation } = useUrlLocation();
  const theme = getThemeType(urlLocation);
  const [appDependencies] = useState(() => initDependencies(appnebulaConfig));

  const { featureFlags, services } = appDependencies;

  const [appStore] = useState<Store<RootInitialState>>(() => createStore(services, featureFlags));

  useLayoutEffect(() => {
    services.analyticsService.launch();
    services.loggingService.info('App services initialized');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    services.heatmapRecordingService.startRecording();
  }, [services]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [urlLocation.pathname]);

  return {
    config: appnebulaConfig,
    appStore,
    theme,
    featureFlags,
    services,
  };
};
