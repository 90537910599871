;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"Ob8tFT41upATOpMTv06oU"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { appnebulaConfig } from 'src/app/appnebulaConfig';

if (appnebulaConfig.isProduction) {
  Sentry.init({
    dsn: appnebulaConfig.sentryDsn,
    release: appnebulaConfig.sentryRelease,
    tracesSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    debug: true,
    ignoreErrors: [/Minified\s+React\s+error\s+#(418|423)/gi],
  });
}
